<template>
  <!-- Seller data -->
  <b-row v-if="parcel.seller">
    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Seller name"
        rules="required"
      >
        <label>Seller name</label>
        <b-form-input
          v-model="parcel.seller.name"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Seller IOSS number</label>
      <b-form-input
        v-model="parcel.seller.registry_number"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Seller street number"
        rules="required"
      >
        <label>Seller street number</label>
        <b-form-input
          v-model="parcel.seller.street_and_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Seller postal code"
        rules="required|max:20"
      >
        <label>Seller postal code</label>
        <b-form-input
          v-model="parcel.seller.postal_code"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Seller city"
        rules="required"
      >
        <label>Seller city</label>
        <b-form-input
          v-model="parcel.seller.city"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Seller region</label>
      <b-form-input
        v-model="parcel.seller.region"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Seller country"
        rules="required|min:2|max:2|alpha"
      >
        <label>Seller country</label>
        <b-form-input
          v-model="parcel.seller.country"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>
