import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function handleError(error, toast, forceTitle = null) {
  if (error.response && error.response.data.message) {
    const errors = error.response.data.message
    let title = forceTitle
    if (!title) {
      title = errors
    }
    toast({
      component: ToastificationContent,
      props: {
        title: title,
        errors: typeof errors === 'object' ? Object.keys(errors).flatMap(key => errors[key]) : null,
        icon: 'EditIcon',
        variant: 'danger',
      },
    })
  } else {
    toast({
      component: ToastificationContent,
      props: {
        title: typeof error === 'string' ? error : 'Encountered an unknown error.',
        icon: 'EditIcon',
        variant: 'danger',
      },
    })
  }
}
